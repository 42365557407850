<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Work Permit Closing List",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Work Permit Closing List",
            // variable Page Table
            loadingTable: true,
            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            searchDataTable: "",
            filter_work_type: [],
            filter_work_status: [],
            work_type_selected: "All",
            work_status_selected: "All",
            modal_qr: false,
            qr_path: "",
            hak_akses: false,

            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: true, // untuk filtering data
                tanggal_dari: null,
                tanggal_sampai: null,
                role_id: localStorage.session_role_active_id,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    mounted() {
        var config_type = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_type)
            .then((response) => {
                console.log(response, "response data type");
                var datatype = ["All"];
                response.data.data[0].data.map((item) => {
                    datatype.push(item.mps_nama);
                });
                this.filter_work_type = datatype;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });
        var config_status = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/status-permohonan",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_status)
            .then((response) => {
                var datastatus = ["All"];
                response.data.data[0].data.map((item) => {
                    datastatus.push(item.msp_nama);
                });
                this.filter_work_status = datastatus;
                Swal.close();
            })
            .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                console.log(this.axiosCatchError);
                Swal.close();
            });

        // load data table
        this.getDataTable();
    },
    methods: {
        getDataTable() {
            let self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix?.data;
                        self.pagingTable = response_data_fix?.links;
                        self.showDataToTable = response_data_fix?.to;
                        self.showDataFromTable = response_data_fix?.from;
                        self.totalDataTable = response_data_fix?.total;
                        self.currentTablePage = response_data_fix?.current_page;
                        self.hak_akses = response_data?.data.hak_akses_add_workpermit_closing;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        filterPermohonan() {
            this.loadingTable = true;
            var self = this;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                params: {
                    filter_work_type: self?.work_type_selected,
                    filter_work_status: self?.work_status_selected,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix?.data;
                        self.pagingTable = response_data_fix?.links;
                        self.showDataToTable = response_data_fix?.to;
                        self.showDataFromTable = response_data_fix?.from;
                        self.totalDataTable = response_data_fix?.total;
                        self.currentTablePage = response_data_fix?.current_page;
                        Swal.close();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        toPage(url) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix?.data;
                        self.pagingTable = response_data_fix?.links;
                        self.showDataToTable = response_data_fix?.to;
                        self.showDataFromTable = response_data_fix?.from;
                        self.totalDataTable = response_data_fix?.total;
                        self.currentTablePage = response_data_fix?.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        searchTable() {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                params: {
                    search: self.searchDataTable,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix?.data;
                        self.pagingTable = response_data_fix?.links;
                        self.showDataToTable = response_data_fix?.to;
                        self.showDataFromTable = response_data_fix?.from;
                        self.totalDataTable = response_data_fix?.total;
                        self.currentTablePage = response_data_fix?.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        showModal(qr_path) {
            this.qr_path = process.env.VUE_APP_BACKEND_URL + "/" + qr_path;
            this.modal_qr = true;
        },
        changeStatusData(id) {
            let self = this;

            Swal.fire({
                title: "Perhatian",
                text: "Are you sure to delete this data?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Yes`,
                denyButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "work-permit-closing",
                        data: {
                            id: id,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Success delete data.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.searchTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
        filterUniqueArray(array) {
            var unique_array = [...new Set(array.map((item) => item.work_permit_type_nama))];
            return unique_array;
        },
        getStatusList(data_detail) {
            var status_berjalan = data_detail?.work_permit_request?.status_berjalan?.mspk_msp_nama;
            var timeline = data_detail?.work_permit_request_approval;
            var last_timeline = timeline.slice(-1)[0];
            // console.log(last_timeline);
            var htmls = status_berjalan + "<br>";
            if (last_timeline.timeline_kategori == "tolak") {
                htmls += "<b>Remark : </b>" + last_timeline.remarks;
            }
            return htmls;
        },
    },
};
</script>
<style scoped>
.bodyborder {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    text-align: center;
}
</style>

<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="row">
                                            <!-- <div class="col-md-3">
                                                <div class="form-group">
                                                    <b for="">Work Permit Type</b>
                                                    <v-select :options="filter_work_type" label="mps_nama" v-model="work_type_selected"></v-select>
                                                </div>
                                            </div> -->
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <b for="">Work Permit Status</b>
                                                    <v-select :options="filter_work_status" label="msp_nama" v-model="work_status_selected"></v-select>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <br />
                                                <div class="btn btn-primary btn-sm" v-on:click="filterPermohonan"><i class="fa fa-filter"></i> Filter</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="text-end">
                                            <br />
                                            <router-link v-if="hak_akses" :to="{ name: 'add-work-permit-closing' }" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Add Work Permit</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <br />
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">
                                        <!-- Untuk Filter Data -->
                                        &nbsp;
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control" id="searchTable" v-on:change="searchTable" v-model="searchDataTable" placeholder="Search Data ..." />
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-sm table-hover table-striped">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>Work Permit Number</th>
                                                <!-- <th>Work Permit Closing Number</th> -->
                                                <th>Work Permit Closing Date</th>
                                                <th>Work Permit Closing Type</th>
                                                <th>Work Permit Closing Status</th>
                                                <th>Initiator Name</th>
                                                <th style="width: 175px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="8"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td class="text-center">
                                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                                    <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_request?.work_permit_no">
                                                        {{ row_data?.work_permit_request?.work_permit_no }}
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <!-- <td>
                                                    <div v-if="row_data?.work_permit_no">
                                                        {{ row_data?.work_permit_no }}
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td> -->
                                                <td>
                                                    <div v-if="row_data?.work_permit_date">
                                                        {{ row_data?.work_permit_date }}
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-if="row_data?.work_permit_type">
                                                        {{ row_data?.work_permit_type }}
                                                        <ul v-if="row_data?.work_permit_request?.work_permit_request_detail">
                                                            <li v-for="(row_type, key_type) in filterUniqueArray(row_data?.work_permit_request?.work_permit_request_detail)" :key="key_type">
                                                                {{ row_type }}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-else>Data Tidak Tersedia</div>
                                                </td>
                                                <td>
                                                    <div v-html="getStatusList(row_data)"></div>
                                                </td>
                                                <!-- <td>
                                                    {{ row_data?.status_permit }}
                                                </td> -->
                                                <td>
                                                    {{ row_data?.user?.name }}
                                                </td>
                                                <td style="text-align: center">
                                                    <div v-if="row_data?.status_permit == 'Draft Work Permit Closing'">
                                                        <router-link :to="{ name: 'edit-work-permit-closing', params: { closingPermitId: row_data.id } }" class="btn btn-sm btn-warning" style="margin-right: 10px"><i class="fa fa-edit"></i> Edit</router-link>
                                                        <b-button class="btn btn-sm btn-danger" v-on:click="changeStatusData(row_data.id)"><i class="fa fa-trash"></i> Delete</b-button>
                                                    </div>
                                                    <div v-else>
                                                        <router-link :to="{ name: 'work-permit-detail', params: { permitId: row_data.work_permit_request_id } }" class="btn btn-sm btn-info" style="margin-right: 10px"><i class="fa fa-eye"></i> Detail</router-link>
                                                        <b-button type="button" variant="warning" class="btn btn-sm" @click="showModal(row_data?.qr_path)"><i class="fas fa-qrcode"></i> See QR</b-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}</div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li
                                                v-for="(page, key_page) in pagingTable"
                                                v-bind:class="{
                                                    active: page.active,
                                                }"
                                                :key="key_page"
                                                class="page-item">
                                                <div v-if="page.url" v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <b-modal v-model="modal_qr" hide-footer centered header-class="border-0" title="QR Code Work Permit">
            <div class="mb-4">
                <div class="row">
                    <div class="col-md-12" style="text-align: center">
                        <img :src="qr_path" style="text-align: center" />
                    </div>
                </div>
            </div>
            <div class="text-end mt-3 button-items">
                <!-- <b-button variant="light" v-on:click="modal_qr = false"> <i class="bx bx-x"></i> Close </b-button> -->
            </div>
        </b-modal>
    </Layout>
</template>
